import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import GatsbyImage from 'gatsby-image'

interface QueryResult {
  file: {
    childImageSharp: {
      fluid: {
        tracedSVG: string
      }
    }
  }
}

const Logo = () => {
  const logo: QueryResult = useStaticQuery(query)
  return <GatsbyImage alt="kuhu" fluid={logo.file.childImageSharp.fluid} />
}

export default Logo

const query = graphql`
  query {
    file(sourceInstanceName: { eq: "images" }, name: { eq: "logo" }) {
      childImageSharp {
        fluid(quality: 85, maxWidth: 400) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
